import { useLocation, useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import InputField from '@rewardopl/react-ui/input_field';

import { post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper } from './form.styles';

import BirthdayField from './birthday_field';

import { cardSubscriptionsState, maybeCurrentUserState } from '../../../../store';

import { APP_ID, VENDOR_CARD_ID } from '../../../../constants';

import type { CardSubscription, User } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

export default function RegisterWithDetailsStepDetailsForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const setCurrentUser = useSetAtom(maybeCurrentUserState);
  const setCardSubscriptions = useSetAtom(cardSubscriptionsState);
  const registerErrorString = useTranslation('Failed to register');
  const userAlreadyExistsString = useTranslation('User already exists');

  const {
    email,
    password,
    termsAccepted,
    marketingAccepted,
    marketingEmailAccepted,
    marketingSmsAccepted,
    returnPath = '/',
  } = (location.state || {}) as {
    email: string;
    password: string;
    termsAccepted: boolean;
    marketingAccepted: boolean | null;
    marketingEmailAccepted: boolean | null;
    marketingSmsAccepted: boolean | null;
    returnPath?: string;
  };

  const action = '/api/users';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);
    const filteredFormData = new FormData();

    for (const [key, value] of formData.entries()) {
      if (value !== '') {
        filteredFormData.append(key, value);
      }
    }

    filteredFormData.append('email', email);
    filteredFormData.append('password', password);
    filteredFormData.append('terms_accepted', termsAccepted.toString());
    if (marketingAccepted !== null) {
      filteredFormData.append('marketing_accepted', marketingAccepted.toString());
    }
    if (marketingEmailAccepted !== null) {
      filteredFormData.append('marketing_email_accepted', marketingEmailAccepted.toString());
    }
    if (marketingSmsAccepted !== null) {
      filteredFormData.append('marketing_sms_accepted', marketingSmsAccepted.toString());
    }

    try {
      const user = (await post(action, {
        headers: {
          'app-id': APP_ID,
        },
        body: filteredFormData,
      })) as User;

      const response = (await post(`/api/users/${user._id}/card_subscriptions`, {
        body: { card_id: VENDOR_CARD_ID },
      })) as CardSubscription;

      setCurrentUser(user);
      setCardSubscriptions([response]);
    } catch (error) {
      if (error instanceof Error && error.message === 'User already exists') {
        toast.error(userAlreadyExistsString);
        navigate('/login/email', { state: { email: formData.get('email'), returnPath } });
        return;
      }

      toast.error(registerErrorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <InputField
        autoComplete="given-name"
        label="First name"
        name="first_name"
        required={false}
        type="text"
      />
      <BirthdayField
        hint="Get a gift for your birthday"
        label="Birthday"
        name="birthday"
        required={false}
      />
      <InputField autoComplete="tel" label="Mobile" name="mobile" required={false} type="tel" />
      <InputField
        autoComplete="postal-code"
        label="Postcode"
        name="address[postcode]"
        placeholder="00-000"
        required={false}
        type="text"
      />
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Register</T>
        </AsyncButton>
      </ButtonWrapper>
    </Form>
  );
}
