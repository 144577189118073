import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.nav`
  width: 100%;
  position: relative;
  margin: 0 auto;
  overflow: auto;
  user-select: none;

  @media screen and (min-width: 769px) {
    margin: 0;
    overflow: unset;
  }
`;

export const List = styled.ul<{ itemCount: number }>`
  --menu-gap: 10px;
  display: flex;
  list-style: none;
  justify-content: ${(props) => (props.itemCount >= 5 ? 'space-between' : 'space-around')};
  padding: 0 10px;
  gap: var(--menu-gap);

  @media screen and (min-width: 769px) {
    flex-direction: column;
    padding: 0 5px;
  }
`;

export const ListItem = styled.li`
  flex-basis: min-content;
  min-width: 66px;
  max-width: 74px;
  height: 100%;
  text-align: center;

  &:empty {
    display: none;
  }

  @media screen and (min-width: 769px) {
    max-width: unset;
    width: auto;
    text-align: left;
  }
`;

export const ListItemUnderlay = styled.div`
  display: none;
  pointer-events: none;

  @media screen and (min-width: 769px) {
    display: block;
    position: absolute;
    z-index: -1;
    inset: calc((var(--menu-gap) / 2)) 0;
    border-radius: 8px;
    background-color: rgba(var(--primary-light-rgb), 0.5);
    opacity: 0;
    scale: 0.85;
    transition:
      background-color ease-in-out 200ms,
      opacity ease-out 100ms,
      scale ease-out 100ms;
  }
`;

export const ListItemLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 8px 0;
  color: inherit;
  text-decoration: none;
  font-size: 0.8em;
  gap: 5px;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @media screen and (min-width: 769px) {
    min-height: calc(40px + var(--menu-gap));
    flex-direction: row;
    margin: calc((var(--menu-gap) / 2) * -1) 0;
    padding: calc(8px + (var(--menu-gap) / 2)) 12px;
    padding-left: calc(var(--gap) - 10px);
    border-radius: 8px;
    font-size: inherit;
    transition: background-color ease-in-out 200ms;
    gap: 8px;

    &:hover,
    &:focus {
      ${ListItemUnderlay} {
        opacity: 1;
        scale: 1;
      }
    }

    &.active {
      color: var(--background);

      ${ListItemUnderlay} {
        background-color: var(--primary-light);
        opacity: 1;
        scale: 1;
      }
    }
  }
`;

export const TabIndicator = styled.div`
  position: absolute;
  top: 0;
  width: 26px;
  height: 2px;
  margin: 0 !important;
  background: var(--active-menu-item);
  border-radius: var(--border-radius-round);
  transform: translateX(-50%);

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const Icon = styled.span`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: inherit;
  line-height: 0;

  img,
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Label = styled.span`
  font-size: 10px;
  letter-spacing: 0.4px;

  @media screen and (min-width: 769px) {
    font-size: 14px;
  }
`;

export const Badge = styled.span`
  min-width: 25px;
  height: 19px;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0 4px;
  background: var(--primary);
  border-radius: var(--border-radius-round);
  color: white;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  @media screen and (min-width: 769px) {
    position: static;
    margin: -2px 0;
    margin-left: auto;
  }
`;
