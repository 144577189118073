import { useEffect, useMemo, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import { matchPath, useLocation } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import T from '@wojtekmaj/react-t';

import { HomeIcon, MailIcon, MapPinIcon, SaladIcon, type LucideIcon } from 'lucide-react';

import {
  Wrapper,
  List,
  ListItem,
  ListItemLink,
  ListItemUnderlay,
  TabIndicator,
  Icon,
  Label,
  Badge,
} from './menu.styles';

import { unreadVendorMessagesCountLoadable } from '../../../store';

const AnimatedTabIndicator = animated(TabIndicator);

type Item = {
  label: string;
  icon: LucideIcon;
  href: string;
  exact?: boolean;
};

type ItemWithBadge = Item & { badge?: number };

type ItemWithBadgeAndFlags = ItemWithBadge & { active: boolean };

const items = [
  {
    label: 'Home',
    icon: HomeIcon,
    href: '/',
    exact: true,
  },
  {
    label: 'Menu',
    icon: SaladIcon,
    href: 'products',
  },
  {
    label: 'Map',
    icon: MapPinIcon,
    href: 'map',
  },
  {
    label: 'Messages',
    icon: MailIcon,
    href: 'inbox',
  },
] satisfies Item[];

export default function Menu() {
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const unreadVendorMessagesCountLoadableValue = useAtomValue(unreadVendorMessagesCountLoadable);
  const tabWrapper = useRef<HTMLUListElement>(null);
  const shouldUseImmediate = useRef(true);
  const [indicatorRect, setIndicatorRect] = useState<object>({ left: 0, opacity: 1 });

  const props = useSpring(indicatorRect);

  const unreadVendorMessagesCount =
    unreadVendorMessagesCountLoadableValue.state === 'hasData'
      ? unreadVendorMessagesCountLoadableValue.data
      : null;

  const itemsWithBadge = useMemo<ItemWithBadge[]>(
    () =>
      unreadVendorMessagesCount
        ? items.map((item) =>
            item.href === 'inbox' ? { ...item, badge: unreadVendorMessagesCount } : item,
          )
        : items,
    [unreadVendorMessagesCount],
  );

  const itemsWithBadgeAndFlags = useMemo<ItemWithBadgeAndFlags[]>(
    () =>
      itemsWithBadge.map((item) => {
        const match = matchPath(
          item.href,
          item.exact ? location.pathname : `/${location.pathname.split('/')[1]}`,
        );
        const active = Boolean(match);

        return { ...item, active };
      }),
    [itemsWithBadge, location.pathname],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect intentionally triggered on window width change
  useEffect(() => {
    shouldUseImmediate.current = true;
  }, [windowWidth]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect intentionally triggered on location change
  useEffect(() => {
    if (!tabWrapper.current) {
      return;
    }

    const activeTab = tabWrapper.current.querySelector('.active');

    function getRect() {
      if (!tabWrapper.current) {
        return;
      }

      if (!activeTab) {
        setIndicatorRect({
          opacity: 0,
          immediate: true,
        });

        shouldUseImmediate.current = true;

        return;
      }

      const rect = activeTab.getBoundingClientRect();

      if (!rect.width && !rect.height) {
        requestAnimationFrame(getRect);
        return;
      }

      const parentRect = tabWrapper.current.getBoundingClientRect();

      const immediate = shouldUseImmediate.current;

      setIndicatorRect({
        left: rect.x - parentRect.x + rect.width / 2,
        opacity: 1,
        immediate,
      });

      shouldUseImmediate.current = false;
    }

    getRect();
  }, [location.pathname, windowWidth]);

  useEffect(() => {
    if (unreadVendorMessagesCount) {
      if ('setAppBadge' in navigator) {
        navigator.setAppBadge(unreadVendorMessagesCount);
      }
    } else {
      if ('clearAppBadge' in navigator) {
        navigator.clearAppBadge();
      }
    }
  }, [unreadVendorMessagesCount]);

  return (
    <Wrapper>
      <List itemCount={itemsWithBadgeAndFlags.length} ref={tabWrapper} role="list">
        {itemsWithBadgeAndFlags.map((item) => {
          const IconComponent = item.icon;

          return (
            <ListItem key={item.label}>
              <ListItemLink to={item.href} end={item.exact}>
                <ListItemUnderlay />
                <Icon>
                  <IconComponent />
                </Icon>
                <Label>
                  <T>{item.label}</T>
                </Label>
                {item.badge ? <Badge>{item.badge}</Badge> : null}
              </ListItemLink>
            </ListItem>
          );
        })}
      </List>
      <AnimatedTabIndicator style={props} />
    </Wrapper>
  );
}
